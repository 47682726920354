/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContactAddressContactListViewModel } from './contactAddressContactListViewModel';
import { ContactListViewModel } from './contactListViewModel';
import { LeadProjectChecklistPhaseSummaryViewModel } from './leadProjectChecklistPhaseSummaryViewModel';


export interface LeadListViewModel { 
    Id?: number;
    PublicLeadId?: string;
    ContactDesired?: boolean;
    Date?: Date;
    MandantId?: number;
    ModiDate?: Date;
    OrganizationalUnitId?: number;
    Origin?: string;
    Tag?: string;
    Tracers?: string;
    OwnerId?: string;
    OwnerFirstName?: string;
    OwnerLastName?: string;
    DelegateId?: string;
    ResponsibleId?: string;
    ContactAddressContact?: ContactAddressContactListViewModel;
    ObjectAddressContact?: ContactListViewModel;
    DebitorAddressContact?: ContactListViewModel;
    Canton?: string;
    PreviousStatus?: string;
    PreviousStage?: string;
    PreviousOwnerId?: string;
    Stage?: string;
    Status?: string;
    Rating?: number;
    TradeTypeCode?: string;
    MandatorSiblingLeadCount?: number;
    TotalSiblingLeadCount?: number;
    Zugangsart?: string;
    PlannedImplementationTime?: LeadListViewModel.PlannedImplementationTimeEnum;
    IsCallbackTimeMorning?: boolean;
    IsCallbackTimeAfternoon?: boolean;
    IsCallbackTimeEvening?: boolean;
    IsCallbackTimeWeekend?: boolean;
    EarliestOpenTaskDueDate?: Date;
    LatestAppointmentDueDate?: Date;
    PriorityDate?: Date;
    PreferredCalendarWeek?: string;
    MandatorShortName?: string;
    LeadLanguage?: string;
    PhoneNumber?: string;
    ProjectManagerId?: string;
    ProjectManagerFirstName?: string;
    ProjectManagerLastName?: string;
    SalesId?: string;
    SalesFirstName?: string;
    SalesLastName?: string;
    ExecutingOrgUnit?: string;
    ExecutingOrgUnitId?: number;
    SalesOrganizationUnitIdByZip?: number;
    PrimarySalesUserIdByZip?: string;
    WonDate?: Date;
    ProjectOrderNumber?: string;
    ProjectAdditionalComponents?: Array<string>;
    ProjectType?: string;
    ProjectTypeFullName?: string;
    ProjectSize?: number;
    FinancingType?: string;
    ProjectReasonTimeConstraints?: string;
    ProjectCanBeStarted?: boolean;
    ProjectCanBeFinished?: boolean;
    ProjectCanBeAccounted?: boolean;
    ProjectCanBeArchived?: boolean;
    ProjectStartInstallationDate?: Date;
    ProjectChecklistPhases?: Array<LeadProjectChecklistPhaseSummaryViewModel>;
    ProjectPhase?: string;
    ProjectName?: string;
    ProjectSapNumber?: string;
    LastSynchTimeStamp?: Date;
    SynchFailed?: boolean;
    LastProjectReviewDate?: Date;
    MasterDataAdditionalComponentIds?: string;
    CheckListStepsRaw?: string;
    SharepointServerAbsoluteUrl?: string;
    ProjectChecklistSopListId?: number;
}
export namespace LeadListViewModel {
    export type PlannedImplementationTimeEnum = 'AsSoonAsPossible' | 'FourToTwelveMonths' | 'OneToTwoYears' | 'Unknown';
    export const PlannedImplementationTimeEnum = {
        AsSoonAsPossible: 'AsSoonAsPossible' as PlannedImplementationTimeEnum,
        FourToTwelveMonths: 'FourToTwelveMonths' as PlannedImplementationTimeEnum,
        OneToTwoYears: 'OneToTwoYears' as PlannedImplementationTimeEnum,
        Unknown: 'Unknown' as PlannedImplementationTimeEnum
    };
}
