/**
 * Helion.Api.PC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LeadRequestWithSop { 
    /**
     * the index of the current page(starts at 1)
     */
    PageIndex?: number;
    /**
     * the amount of entries per page
     */
    PageSize?: number;
    SortField?: LeadRequestWithSop.SortFieldEnum;
    SortOrder?: LeadRequestWithSop.SortOrderEnum;
    /**
     * search text to filter leads
     */
    SearchText?: string;
    /**
     * filter for entries with this customer name (includes first and last name in any order as well as company and additional company name)
     */
    CustomerName?: string;
    /**
     * only retrieve entries with a matching company name and additional company name
     */
    CompanyName?: string;
    /**
     * filter for entries with this owner name (includes first and last name in any order)
     */
    OwnerName?: string;
    /**
     * only retrieve entries with a matching street (of the customer contact, object or debitor contact)
     */
    Street?: string;
    /**
     * only retrieve entries with a matching city (of the customer contact, object or debitor contact)
     */
    City?: string;
    /**
     * only retrieve entries with a matching zip code (of the customer contact, object or debitor contact)
     */
    ZipCode?: string;
    /**
     * >only retrieve entries with a matching mandator id
     */
    MandantorIds?: Array<number>;
    /**
     * only retrieve entries with a matching organization unit id
     */
    OrganizationUnits?: Array<number>;
    /**
     * only retrieve entries with a matching executing organization unit id
     */
    ExecutingOrganizationUnitIds?: Array<number>;
    /**
     * only retrieve entries with a matching sales organization unit zip code (aka postCode, or PLZ)
     */
    SalesOrganisationUnitIds?: Array<number>;
    /**
     * only retrieves entries from this primary sales user id
     */
    PrimarySalesUserId?: string;
    /**
     * only retrieve entries which have this tag
     */
    Tag?: string;
    /**
     * only retrieve entries which have these tracers
     */
    Tracers?: Array<string>;
    /**
     * only retrieve entries which come from these origins
     */
    Origins?: Array<string>;
    /**
     * only retrieve entries which come from these origins. This field will be appended to the \"Origins\" field.
     */
    OriginsFreeText?: Array<string>;
    /**
     * only retrieve entries which were created by a person with this email
     */
    Email?: string;
    /**
     * only retrieve entries which were created by a person with this phone number
     */
    PhoneNumber?: string;
    /**
     * only retrieve entries from this owner or this delegate
     */
    OwnerOrDelegateId?: string;
    /**
     * only retrieve entries from this responsible (the delegate if the lead has one, or else the owner)
     */
    ResponsibleId?: string;
    /**
     * only retrieve entries from this sales id
     */
    SalesId?: string;
    /**
     * only retrieve entries from this sales name
     */
    SalesName?: string;
    /**
     * only retrieve entries from all of those project manager ids
     */
    ProjectManagerIds?: Array<string>;
    /**
     * only retrieve entries from this project manager name
     */
    ProjectManagerName?: string;
    /**
     * only retrieve entries from this planner id
     */
    PlannerId?: string;
    /**
     * only retrieve entries from this planner name
     */
    PlannerName?: string;
    /**
     * only retrieve entries from this planner ac id
     */
    PlannerAcId?: string;
    /**
     * only retrieve entries from this planner ac name
     */
    PlannerAcName?: string;
    LeadLanguage?: LeadRequestWithSop.LeadLanguageEnum;
    /**
     * only retrieve entries with this access type
     */
    AccessType?: string;
    /**
     * only retrieve entries with a matching lead stages
     */
    Stages?: Array<string>;
    /**
     * only retrieve entries with a matching lead status
     */
    Status?: Array<string>;
    /**
     * only retrieve entries with a matching variantId
     */
    VariantId?: number;
    /**
     * only retrieve entries with a matching leadId
     */
    LeadId?: number;
    /**
     * only retrieve entries with a matching PublicLeadId
     */
    PublicLeadId?: string;
    /**
     * only retrieve entries with a matching trade type code
     */
    TradeType?: string;
    /**
     * if set to true, all leads which the current user is the previous owner are also selected
     */
    WithPreviousOwnerLeads?: boolean;
    ZipCity?: string;
    AddressLookupType?: LeadRequestWithSop.AddressLookupTypeEnum;
    ProjectSapNumber?: string;
    ProjectName?: string;
    ProjectChecklistTemplateTitle?: string;
    DateRangeType?: LeadRequestWithSop.DateRangeTypeEnum;
    DateRangeStart?: Date;
    DateRangeEnd?: Date;
    EnergySolutionsApprovedState?: LeadRequestWithSop.EnergySolutionsApprovedStateEnum;
    SopListId?: number;
    /**
     * If set, only return entries that match one or multiple of these additional components.
     */
    AdditionalComponents?: Array<string>;
    /**
     * Enables filtering leads after completion of a specific SOP Step.  Only leads with checklists that are linked to and have completed the specified SOP Step will be returned.
     */
    SopListSteps?: Array<number>;
}
export namespace LeadRequestWithSop {
    export type SortFieldEnum = 'None' | 'LeadId' | 'LeadStage' | 'LeadStatus' | 'Created' | 'OwnerId' | 'AccessType' | 'MandatorId' | 'OrganisationUnitId' | 'PersonContactCity' | 'PersonLastName' | 'PersonFirstName' | 'PersonContactStreet' | 'PersonContactZipCode' | 'ObjectAddressCity' | 'ObjectAddressLastName' | 'ObjectAddressFirstName' | 'ObjectAddressStreet' | 'ObjectAddressZipCode' | 'Rating' | 'PersonLanguage' | 'TradeType' | 'HasOpenTasks' | 'Tag' | 'WonDate' | 'ProjectManagerId' | 'ProjectOrderNumber' | 'ProjectChecklistCompletionDefinition' | 'ProjectChecklistCompletionPlanning' | 'ProjectChecklistCompletionSourcing' | 'ProjectChecklistCompletionPreparation' | 'ProjectChecklistCompletionInstallation' | 'ProjectChecklistCompletionClosure' | 'PersonPhoneNumber' | 'Tracers' | 'Origin' | 'Language' | 'OwnerFirstName' | 'MandatorShortName' | 'ExecutingOrgUnit' | 'ProjectManagerFirstName' | 'PriorityDate' | 'ProjectStartInstallationDate' | 'ProjectAdditionalComponents' | 'ProjectSize' | 'ProjectType' | 'FinancingType' | 'Canton';
    export const SortFieldEnum = {
        None: 'None' as SortFieldEnum,
        LeadId: 'LeadId' as SortFieldEnum,
        LeadStage: 'LeadStage' as SortFieldEnum,
        LeadStatus: 'LeadStatus' as SortFieldEnum,
        Created: 'Created' as SortFieldEnum,
        OwnerId: 'OwnerId' as SortFieldEnum,
        AccessType: 'AccessType' as SortFieldEnum,
        MandatorId: 'MandatorId' as SortFieldEnum,
        OrganisationUnitId: 'OrganisationUnitId' as SortFieldEnum,
        PersonContactCity: 'PersonContactCity' as SortFieldEnum,
        PersonLastName: 'PersonLastName' as SortFieldEnum,
        PersonFirstName: 'PersonFirstName' as SortFieldEnum,
        PersonContactStreet: 'PersonContactStreet' as SortFieldEnum,
        PersonContactZipCode: 'PersonContactZipCode' as SortFieldEnum,
        ObjectAddressCity: 'ObjectAddressCity' as SortFieldEnum,
        ObjectAddressLastName: 'ObjectAddressLastName' as SortFieldEnum,
        ObjectAddressFirstName: 'ObjectAddressFirstName' as SortFieldEnum,
        ObjectAddressStreet: 'ObjectAddressStreet' as SortFieldEnum,
        ObjectAddressZipCode: 'ObjectAddressZipCode' as SortFieldEnum,
        Rating: 'Rating' as SortFieldEnum,
        PersonLanguage: 'PersonLanguage' as SortFieldEnum,
        TradeType: 'TradeType' as SortFieldEnum,
        HasOpenTasks: 'HasOpenTasks' as SortFieldEnum,
        Tag: 'Tag' as SortFieldEnum,
        WonDate: 'WonDate' as SortFieldEnum,
        ProjectManagerId: 'ProjectManagerId' as SortFieldEnum,
        ProjectOrderNumber: 'ProjectOrderNumber' as SortFieldEnum,
        ProjectChecklistCompletionDefinition: 'ProjectChecklistCompletionDefinition' as SortFieldEnum,
        ProjectChecklistCompletionPlanning: 'ProjectChecklistCompletionPlanning' as SortFieldEnum,
        ProjectChecklistCompletionSourcing: 'ProjectChecklistCompletionSourcing' as SortFieldEnum,
        ProjectChecklistCompletionPreparation: 'ProjectChecklistCompletionPreparation' as SortFieldEnum,
        ProjectChecklistCompletionInstallation: 'ProjectChecklistCompletionInstallation' as SortFieldEnum,
        ProjectChecklistCompletionClosure: 'ProjectChecklistCompletionClosure' as SortFieldEnum,
        PersonPhoneNumber: 'PersonPhoneNumber' as SortFieldEnum,
        Tracers: 'Tracers' as SortFieldEnum,
        Origin: 'Origin' as SortFieldEnum,
        Language: 'Language' as SortFieldEnum,
        OwnerFirstName: 'OwnerFirstName' as SortFieldEnum,
        MandatorShortName: 'MandatorShortName' as SortFieldEnum,
        ExecutingOrgUnit: 'ExecutingOrgUnit' as SortFieldEnum,
        ProjectManagerFirstName: 'ProjectManagerFirstName' as SortFieldEnum,
        PriorityDate: 'PriorityDate' as SortFieldEnum,
        ProjectStartInstallationDate: 'ProjectStartInstallationDate' as SortFieldEnum,
        ProjectAdditionalComponents: 'ProjectAdditionalComponents' as SortFieldEnum,
        ProjectSize: 'ProjectSize' as SortFieldEnum,
        ProjectType: 'ProjectType' as SortFieldEnum,
        FinancingType: 'FinancingType' as SortFieldEnum,
        Canton: 'Canton' as SortFieldEnum
    };
    export type SortOrderEnum = 'None' | 'Asc' | 'Desc';
    export const SortOrderEnum = {
        None: 'None' as SortOrderEnum,
        Asc: 'Asc' as SortOrderEnum,
        Desc: 'Desc' as SortOrderEnum
    };
    export type LeadLanguageEnum = 'DE' | 'FR' | 'IT' | 'EN';
    export const LeadLanguageEnum = {
        DE: 'DE' as LeadLanguageEnum,
        FR: 'FR' as LeadLanguageEnum,
        IT: 'IT' as LeadLanguageEnum,
        EN: 'EN' as LeadLanguageEnum
    };
    export type AddressLookupTypeEnum = 'All' | 'Contact' | 'Object' | 'Debitor';
    export const AddressLookupTypeEnum = {
        All: 'All' as AddressLookupTypeEnum,
        Contact: 'Contact' as AddressLookupTypeEnum,
        Object: 'Object' as AddressLookupTypeEnum,
        Debitor: 'Debitor' as AddressLookupTypeEnum
    };
    export type DateRangeTypeEnum = 'Created' | 'LastModified' | 'OpenTasks' | 'ProjectStart' | 'Won' | 'HideFutureTasks';
    export const DateRangeTypeEnum = {
        Created: 'Created' as DateRangeTypeEnum,
        LastModified: 'LastModified' as DateRangeTypeEnum,
        OpenTasks: 'OpenTasks' as DateRangeTypeEnum,
        ProjectStart: 'ProjectStart' as DateRangeTypeEnum,
        Won: 'Won' as DateRangeTypeEnum,
        HideFutureTasks: 'HideFutureTasks' as DateRangeTypeEnum
    };
    export type EnergySolutionsApprovedStateEnum = 'NotSet' | 'Approved' | 'Disapproved';
    export const EnergySolutionsApprovedStateEnum = {
        NotSet: 'NotSet' as EnergySolutionsApprovedStateEnum,
        Approved: 'Approved' as EnergySolutionsApprovedStateEnum,
        Disapproved: 'Disapproved' as EnergySolutionsApprovedStateEnum
    };
}
